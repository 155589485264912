<template>
    <div class="reportEatservetime defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter selected">
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">餐厅:</div>
                        <div class="from-input" style="width: 170px;">
                            <el-select v-model="roomids" multiple collapse-tags @removeTag="()=>roomids=[]" >
                                <el-option :value="item.Room_AutoID" :label="item.Room_Code+'-'+ item.Room_Name" v-for="item in PosRooms" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block">
                    <div class="flex-box eat">
                        <div class="lable-txt">时段:</div>
                        <div class="from-input">
                            <el-select v-model="salesValue" placeholder="全天" multiple collapse-tags @removeTag="()=>salesValue=[]">
                                <el-option
                                    v-for="item in eatPhases"
                                    :key="item.EAT_PHASEID"
                                    :label="item.EAT_PHASENAME"
                                    :value="item.EAT_PHASEID">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">消费类型:</div>
                        <div class="from-input" style="width: 170px;">
                            <select-tree v-model="typeAutoIDs" placeholder="请选择" :data="treeConsumeTypes" title="消费类型选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">销售渠道:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="channelAutoIDs" placeholder="请选择" :data="treeOrderChannel" title="销售渠道选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <el-select class="search-type" v-model="searchType" placeholder="按台号" >
                            <el-option :value="0" label="按桌号"></el-option>
                            <el-option :value="1" label="按菜品"></el-option>
                            <el-option :value="2" label="按账单号"></el-option>
                        </el-select>
                        <div class="from-input search-input"><input type="text" v-model="selectContent"  placeholder="菜品检索" /></div>
                        <div class="search-flex">
                            <div class="bnt-search" @click="GetBillCheck()" >查询</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table headerCol2" ref="tableBox"   >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                    ref="tableEl"
                    border 
                    :span-method="spanMethod"
                    @cell-dblclick="orderDetail"
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column fixed prop="Eat_RoomName" label="餐厅" min-width="110"></el-table-column>
                    <el-table-column fixed prop="Eat_Phase" label="时段" min-width="50"></el-table-column>
                    <el-table-column fixed prop="Eat_CheckIndex" align="left" label="结账单号" min-width="160"></el-table-column>
                    <el-table-column  prop="Eat_DeskName" align="left" label="桌号" min-width="100"></el-table-column>
                    <el-table-column  prop="Eat_State" label="状态" min-width="50">
                        <template #default="scope">
                            {{scope.row.Eat_State==2?"已结":"未结"}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_ManNum" label="人数" min-width="50"></el-table-column>
                    <el-table-column prop="Eat_XFCode" label="菜品编码" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_XFName" label="菜品名称" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_Number" label="下单数量" min-width="60" align="right"></el-table-column>
                    <el-table-column prop="Refund_Number" label="退菜数量" min-width="60" align="right">
                        <template #default="scope">
                            <span class="link" v-if="scope.row.Refund_Number!=0">{{scope.row.Refund_Number}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="40"></el-table-column>
                    <el-table-column prop="ISKDSNOSHOW" label="是否考核" min-width="75">
                        <template #header>
                            <el-tooltip  effect="dark" content="来源菜品设置项【KDS不显示】作为不考菜品" placement="top">
                                <div class="tip">是否考核
                                    <svg class="icon-svg" aria-hidden="true">
                                        <use xlink:href="#icon-infoFilled"></use>
                                    </svg>
                                </div>
                            </el-tooltip>
                        </template>
                        <template #default="scope">
                            <template v-if="scope.isExcel">
                                {{scope.row.ISKDSNOSHOW?"否":"是"}}
                            </template>
                            <span class="kdsNoShow" v-else>
                                <i class="iconfont icon-cha" v-if="scope.row.ISKDSNOSHOW"></i>
                                <i class="iconfont icon-gou" v-else></i>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Food_level" label="菜品等级" min-width="60"></el-table-column>
                    <el-table-column label="系统标准值(单位:分)">
                        <el-table-column prop="KIT_LENGTH" label="标准时长" min-width="80" >
                            <template #header>
                                <el-tooltip  effect="dark" content="来源菜品设置项【制作标准时长】" placement="top">
                                    <div class="tip">标准时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="EndCookTime" label="理论完成时间" min-width="100" >
                            <template #header>
                                <el-tooltip  effect="dark" content="按制作标准时长和菜品等级运算值" placement="top">
                                    <div class="tip">理论完成时间
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="出菜(单位:分)">
                        <el-table-column prop="RealCookTime" label="出菜时长" min-width="80" >
                            <template #header>
                                <el-tooltip  effect="dark" content="划菜时间-起菜时间" placement="top">
                                    <div class="tip">出菜时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OverTime" label="超时时长" min-width="80" >
                            <template #header>
                                <el-tooltip  effect="dark" content="划菜时间-理论完成时间" placement="top">
                                    <div class="tip">超时时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="TransferFoodTime" label="传菜时长(单位:分)" min-width="80">
                        <template #header>
                            <el-tooltip  effect="dark" content="上桌时间-划菜时间" placement="top">
                                <div class="tip">传菜时长
                                    <svg class="icon-svg" aria-hidden="true">
                                        <use xlink:href="#icon-infoFilled"></use>
                                    </svg>
                                    (单位:分)
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="前厅操作">
                        <el-table-column prop="Eat_Time" label="点菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Eat_Time}}
                                <p class="small">{{scope.row.Eat_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="CallUp_Time" label="起菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.CallUp_Time}}
                                <p class="small">{{scope.row.CallUp_Czy}}</p>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="后厨操作">
                        <el-table-column prop="Cut_Time" label="切配" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Cut_Time}}
                                <p class="small">{{scope.row.Cut_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Do_Time" label="制作" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Do_Time}}
                                <p class="small">{{scope.row.Do_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="End_Time" label="划菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.End_Time}}
                                <p class="small">{{scope.row.End_Czy}}</p>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="OnDesk_Time" label="上桌" min-width="70" >
                        <template #default="scope">
                                {{scope.row.OnDesk_Time}}
                                <p class="small">{{scope.row.OnDesk_Czy}}</p>
                            </template>
                    </el-table-column>
                    <el-table-column prop="Food_Status" label="菜品状态" min-width="70" >
                        <template #default="scope">
                            {{["待切配","已切配 待制作","已制作 待划菜","已划菜 待上桌","已上桌"][scope.row.Food_Status]}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_MasName" label="要求" min-width="160" ></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableFilter" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
     </div>
</template>
<script>

/**菜品实时状态监控表 */
export default {
    name:'reportEatservetime',
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            //检索
            selectContent:"",
            //检索类型 0 按桌号  1 按菜品  2 账单
            searchType:0,
            //餐厅id
            roomids:[],
            //时段数据
            eatPhases:null,
            //时段
            salesValue:[],
            //消费类型数据
            consumeTypes:null,
            //消费类型id
            typeAutoIDs:[],
            //渠道数据
            orderChannel:null,
            //渠道id
            channelAutoIDs:[],
            //桌台数据
            PosRooms:[]
        }
    },
    computed:{
        //大类数据
        foodMainCategory(){
            let foodMainCategory=[];
            this.posFoodData?.data?.map(item=>{
                let depart={
                    id:item.MainCategory_ID,
                    Name:item.MainCategory_Name,
                    children:[],
                    Hierarchy:1
                }
                if(item.FoodSubCategorys){
                    item.FoodSubCategorys.map(eat=>{
                        let chieat={
                            id:eat.SubCategory_ID,
                            Name:eat.SubCategory_Name,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                foodMainCategory.push(depart)
            })
            return foodMainCategory;
        },
        //消费类型数据
        treeConsumeTypes(){
            let treeData=[];
            this.consumeTypes?.map((group)=>{
                let json={
                    id:group.ConsumeTypeKind_AutoID,
                    name:group.ConsumeTypeKind_Code+group.ConsumeTypeKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.ConsumeTypeList){
                    group.ConsumeTypeList.map(item=>{
                        let children= Object.assign({
                            id:item.ConsumeType_AutoID,
                            name:item.ConsumeType_Code+'-'+item.ConsumeType_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
        //渠道数据
        treeOrderChannel(){
            let treeData=[];
            this.orderChannel?.map((group)=>{
                let json={
                    id:group.OrderChannelKind_AutoID,
                    name:group.OrderChannelKind_Code+group.OrderChannelKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.OrderChannelList){
                    group.OrderChannelList.map(item=>{
                        let children= Object.assign({
                            id:item.OrderChannel_AutoID,
                            name:item.OrderChannel_Code+'-'+item.OrderChannel_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
        //合并数据行数
        spanColData(){
            let span=[];
            let json={};
            (this.tableListPage||[]).forEach((it,rowIndex)=>{
                if(rowIndex==0 || (it.Eat_CheckIndex!=this.tableListPage[rowIndex-1].Eat_CheckIndex || it.Eat_DeskName!=this.tableListPage[rowIndex-1].Eat_DeskName)){
                    json=[1,1];
                    span.push(json);
                    return;
                }
                json[0]++;
                span.push([0,0]);
            })
            return span;
        },
        /**筛选 或 排序 */
        tableFilter(){
            let list=this.tableList;
            if(list?.length>0){
                if(this.selectContent!=''){
                    let seach=this.selectContent.toUpperCase();
                    if(this.searchType==0 ){//0 按桌号 
                        list=list.filter((item)=> (item.Eat_DeskName||'').toUpperCase().indexOf(seach)>=0);
                    }else if(this.searchType==1 ){// 1 按菜品  
                        list=list.filter((item)=> (item.Eat_XFCode||'').toUpperCase().indexOf(seach)>=0 || (item.Eat_XFName||'').toUpperCase().indexOf(seach)>=0);
                    }else if(this.searchType==2 ){//2 账单
                        list=list.filter((item)=> (item.Eat_CheckIndex||'').toUpperCase().indexOf(seach)>=0);
                    }
                }
            } 
            
            return list;
        },
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();

        //时段数据
        this.$cacheData.EatPhase().then((d)=>{
            if(d){
                this.eatPhases=d;
            }
        }).catch((e)=>{
            this.$message.error('加载时段数据失败：'+e.message);
            console.log('加载时段数据失败：'+e);
        })

        //渠道数据
        this.$cacheData.OrderChannel().then((d)=>{
            if(d){
                this.orderChannel=d;
            }
        }).catch((e)=>{
            this.$message.error('加载渠道数据失败：'+e.message);
            console.log('加载渠道数据失败：'+e);
        })

        //消费类型
        this.$cacheData.ConsumeTypes().then((d)=>{
            if(d){
                this.consumeTypes=d;
            }
        }).catch((e)=>{
            this.$message.error('加载消费类型数据失败：'+e.message);
            console.log('加载消费类型数据失败：'+e);
        })

        this.$cacheData.EatRooms().then((d)=>{
            this.PosRooms=d;
        }).catch((e)=>{
            this.$alert("桌台数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
        });
    },
    watch:{
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**合并行列 */
        spanMethod({ row, column, rowIndex, columnIndex }){//tableListPage
            if(columnIndex<6){
                return this.spanColData[rowIndex];
            }
            return [1,1]
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"菜品实时状态监控表",
                list:this.tableFilter
            })

            // let _pagesize = this.pagesize
            // this.pagesize = this.tableFilter.length;//表格长度变长
            // this.currentPage= 1;
            // this.$refs.tableBox.style.display="none";
            // this.isExcel=true;
            // this.$nextTick(function () {
            //     let table = this.$refs.tableBox.cloneNode(true);
            //     table.querySelector(".el-table__fixed")?.remove();
            //     table.querySelectorAll(".el-table__footer td")?.forEach(it=>{
            //         it.setAttribute("rowspan","1");
            //     })
            //     let xlsxParam = { raw: true };
            //     let wb = XLSX.utils.table_to_book(table,xlsxParam);
            //     let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
            //     try {
            //         FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '菜品实时状态监控表.xlsx')
            //     } catch (e) {
            //         if (typeof console !== 'undefined') console.log(e, wbout)
            //     }
            //     this.pagesize = _pagesize;//表格还原
            //     this.$nextTick(()=>{
            //         this.$refs.tableBox.style.display='';
            //     })
            //     this.isExcel=false;
            //     return wbout
            // })
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 */
        GetBillCheck(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_PhaseIDs:this.salesValue,
                Eat_RoomIDs:this.roomids,
                ChannelIDs:this.channelAutoIDs,
                XFTypeIDs:this.typeAutoIDs,
                //Eat_XFBMName:this.selectContent
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodRealTimeStatusReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="Refund_Number" && row.Refund_Number!=0){//穿透 退菜时间分析表
                this.$emit("addFrame",{
                    key:"backFood"+row.Eat_AutoID+row.Eat_XFBMID,
                    data:{
                        Rpt_Date:this.userInfo.Rpt_Date,
                        Eat_CheckIndex:row.Eat_CheckIndex,
                        Eat_DeskName:row.Eat_DeskName,
                        Eat_AutoID:row.Eat_AutoID,
                        Eat_XFBMID:row.Eat_XFBMID,
                        Eat_XFName:row.Eat_XFName
                    },
                    title:'退菜时间分析表',
                    url:'backFoodTimeReport'
                });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './reportEatservetime.scss'
</style>